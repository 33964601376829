import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"
import Slider from "react-slick"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"

const RightArrows = props => {
  const { className, style, onClick } = props
  return (
    <button
      className="slider-prev-next-button next"
      type="button"
      aria-label="Next"
      onClick={onClick}
    >
      <FaAngleRight size={32} stroke={"#fff"} strokeWidth={35} />
    </button>
  )
}

const PrevArrows = props => {
  const { className, style, onClick } = props
  return (
    <button
      className="slider-prev-next-button prev"
      type="button"
      aria-label="Previous"
      onClick={onClick}
    >
      <FaAngleLeft size={32} stroke={"#fff"} strokeWidth={35} />
    </button>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  width: 100%;

  @media (max-width: 1350px) {
    width: calc(100% + 40px);
    margin-left: -20px;
  }

  .regular.slider {
    display: flex;
  }

  .slick-slider {
    width: 100%;
    position: relative;
    display: flex;
  }

  .slick-dots {
    bottom: -20px;
    left: 0;
    right: 0;
  }

  .slick-dots li {
    width: 10px;
    height: 10px;
  }

  .slick-dots li button {
    width: 10px;
    height: 10px;
    padding: 0px;
  }

  .slick-dots li button:before {
    width: 10px;
    height: 10px;
    font-size: 11px;
    line-height: 10px;
  }

  .slider-prev-next-button {
    border: 0px solid #262626;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    background: transparent;
    top: 50%;
    transform: translate(0%, -50%);

    @media (max-width: 1300px) {
      top: auto;
      bottom: -32px;
      transform: translate(0%, 0%);
      border: 2px solid #262626;
    }
  }

  .slider-prev-next-button.prev {
    left: -40px;

    @media (max-width: 1300px) {
      left: 25%;
    }

    @media (max-width: 600px) {
      left: 20px;
    }
  }

  .slider-prev-next-button.next {
    right: -40px;

    @media (max-width: 1300px) {
      right: 25%;
    }

    @media (max-width: 600px) {
      right: 20px;
    }
  }
`

const ProductCard = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.white.base};
  color: ${props => props.theme.colors.black.base};
  box-shadow: ${props => props.theme.shadow.card};
  width: 100%;
  min-width: 235px;
  max-width: 235px;
  height: 450px;
  max-height: 450px;
  border-radius: 50px 0px;
  overflow: hidden;
  margin: 15px 25px 15px 5px;
  position: relative;

  @media (max-width: 1300px) {
    margin: 15px 15px 15px 15px;
  }
`

const ProductLink = styled(Link)`
  position: absolute;
  top: 0px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const ProductLinkTitle = styled.span`
  display: none;
`

const TopSection = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #262626;
  min-height: 305px;
  position: relative;
`

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.black.base};
  width: 100%;
  height: 100%;
  padding: ${({ padding }) => (padding ? padding : "25px 20px")};
  position: relative;
`

const ProductBottomInfoWrapper = styled.div`
  position: absolute;
  border-radius: 50px 0px;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
  height: 205px;
  display: flex;
  flex-direction: column;
`

const ProductBottomInfo = styled.div`
  position: relative;
  border-radius: 50px 0px;
  width: 100%;
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`

const ProductLogoBox = styled.div`
  position: absolute;
  top: ${({ productName }) => (productName === "loop" ? "-36px" : "-30px")};
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  background: ${({ productName }) =>
    productName === "lynehome"
      ? "#2653A1"
      : productName === "lynefit"
      ? "#9AD2E9"
      : productName === "loop"
      ? "#213D5B"
      : "#5db9bc"};
  border-radius: 15px 0px 0px 0px;
`

const ProductLogo = styled.img`
  width: 100%;
  max-width: ${({ productName }) =>
    productName === "lynehome"
      ? "90px"
      : productName === "loop"
      ? "36px"
      : "70px"};
  height: ${({ productName }) => (productName === "loop" ? "20px" : "14px")};
  margin: 0px;
`

const ProductTag = styled.div`
  font-family: "BebasNeueBold";
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  color: #262626;
  background: linear-gradient(
    61.74deg,
    #ff7e80 -4.05%,
    #ffa853 52.44%,
    #ffed7d 91.92%
  );
  border-radius: 10px 0px;
  padding: 5px 10px;
  margin-bottom: 5px;
  text-align: center;
  position: absolute;
  top: 15px;
  right: 15px;
`

const ProductImage = styled.img`
  width: 100%;
  margin: 0px;
  height: 100%;
  object-fit: cover;
`

const Price = styled.div`
  font-family: "Gotham Book";
  font-weight: bold;
  font-size: 21px;
  line-height: 1.2;
`

const ProductTitle = styled.p`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 600;
  color: #262626;
  margin: 0px;
  text-align: left;
`

const ProductDescriptionBox = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px;
  padding: 5px 0px 10px 15px;
  text-align: left;

  li {
    font-size: 12px;
    line-height: 1.2;
    font-family: "Gotham Book";
    font-weight: 500;
    color: #262626;
    margin: 0px 0px 5px;
  }
`

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
`

const StyledLink = styled(Link)`
  background: ${props => props.theme.colors.button.black};
  font-family: "Museo";
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  color: ${props => props.theme.colors.white.base};
  font-size: 18px;
  margin-right: 15px;
  padding: 12px 20px;
  line-height: 1.2;
  width: 100%;
  position: relative;
  border-radius: 25px 0px;

  :hover {
    color: #262626;
    background: ${props => props.theme.gradient.buttonGradient1};
    :after {
      background: ${props => props.theme.colors.button.black};
    }
  }

  :after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: -5px;
    bottom: -5px;
    background: ${props => props.theme.gradient.buttonGradient1};
    border-radius: 25px 0;
    z-index: -1;
  }
`

const HomeCrossSlider = ({ intl, data, price }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    variableWidth: true,
    slidesToScroll: 1,
    nextArrow: <RightArrows />,
    prevArrow: <PrevArrows />,
  }

  return (
    data && (
      <Wrapper>
        <SliderWrapper>
          {isBrowser && (
            <Slider {...settings}>
              {data.product_list &&
                data.product_list.map((item, index) => {
                  return (
                    item && (
                      <ProductCard key={index.toString()} to={item.url}>
                        <ProductLink to={item.url}>
                          <ProductLinkTitle>{item.name}</ProductLinkTitle>
                        </ProductLink>
                        <TopSection>
                          {(item.name === "lyneup" ||
                            item.name === "lynefit" ||
                            item.name === "lynehome") && (
                            <ProductTag>Nouveau modèle</ProductTag>
                          )}
                          <ProductImage
                            className="lazyload"
                            data-src={item.image}
                            alt={item.alt ? item.alt : ""}
                          />
                        </TopSection>
                        <BottomSection padding="0px">
                          {item.title && item.text && (
                            <ProductBottomInfoWrapper>
                              <ProductBottomInfo>
                                {item.logo && (
                                  <ProductLogoBox productName={item.name}>
                                    <ProductLogo
                                      className="lazyload"
                                      productName={item.name}
                                      data-src={item.logo}
                                      alt={item.logo_alt ? item.logo_alt : ""}
                                    />
                                  </ProductLogoBox>
                                )}
                                {item.title && (
                                  <ProductTitle
                                    dangerouslySetInnerHTML={{
                                      __html: item.title,
                                    }}
                                  />
                                )}
                                {item.text && (
                                  <ProductDescriptionBox
                                    dangerouslySetInnerHTML={{
                                      __html: item.text,
                                    }}
                                  />
                                )}
                                {item.button_text && item.url && (
                                  <FlexWrapper>
                                    <StyledLink to={item.url}>
                                      {item.button_text}
                                    </StyledLink>
                                    {price && (
                                      <Price>
                                        {process.env.GATSBY_CURRENCY_TYPE !==
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""}
                                        {Number(
                                          price.find(
                                            price_item =>
                                              price_item.node.handle ===
                                              item.name
                                          ).node.priceRangeV2.maxVariantPrice
                                            .amount
                                        ).toFixed(0) || 129}
                                        {process.env.GATSBY_CURRENCY_TYPE ===
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""}
                                      </Price>
                                    )}
                                  </FlexWrapper>
                                )}
                              </ProductBottomInfo>
                            </ProductBottomInfoWrapper>
                          )}
                        </BottomSection>
                      </ProductCard>
                    )
                  )
                })}
            </Slider>
          )}
        </SliderWrapper>
      </Wrapper>
    )
  )
}

export default injectIntl(HomeCrossSlider)
